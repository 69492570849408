<template>
  <transition>
    <div
      v-if="filterToggle"
    >
      <b-form
        class="border-dashed"
      >

        <b-row>
          <b-col md="3">
            <b-row class="flex-column">
              <b-table-simple
                v-for="field in [
                  ServiceOrder.DAMAGED,ServiceOrder.SERVICE
                ]"
                :key="field"
              >
                <b-row class="checkboxGroup">
                  <b-col
                    md="4"
                    class="d-flex justify-content-end"
                  >
                    <b-form-checkbox
                      :id="field"
                      v-model="filters[ServiceOrder.PRODUCT_RULES][field]"
                      class="checkbox ml-auto"
                      value="1"
                      :unchecked-value="null"
                    />
                  </b-col>
                  <b-col md="8">
                    <b-thead>
                      <label
                        :for="field"
                        class="checkboxLabel"
                      >
                        {{ filterClass.getLabel(ServiceOrder.PRODUCT_RULES, field) }}
                      </label>
                    </b-thead>
                  </b-col>

                </b-row>
              </b-table-simple>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button
              variant="success"
              style="min-width: 95px"
              @click="onFilter"
            >
              {{ $t('Apply') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <div v-else>
      <span
        v-if="$route.query.hasOwnProperty('search')"
        class="border-dashed mr-1 d-inline-block"
      >
        <b>Search:</b> <span v-html="$route.query.search" />
        <feather-icon
          icon="LClearIcon"
          class="ml-1 cursor-pointer"
          size="10"
          @click="removeSearchFilter()"
        />
      </span>
      <span
        v-for="selectedFilter in selectedFilters"
        v-if="selectedFilter.path.toString() !== 'is_active'"
        :key="selectedFilter.path.join('.')"
        class="border-dashed mr-1 d-inline-block"
      >
        <span v-html="selectedFilter.value" />
        <feather-icon
          icon="LClearIcon"
          class="ml-1 cursor-pointer"
          size="10"
          @click="removeSelectedFilter(selectedFilter.path)"
        />
      </span>
    </div>
    {{ selectedFilters }}
  </transition>
</template>

<script>
import {
  BButton, BCol, BForm, BFormCheckbox, BRow, BTableSimple, BThead,
} from 'bootstrap-vue'
import ServiceOrder from '@/views/components/filters/fields/orders/service/Service'

export default {
  name: 'ServiceOrderFilterComponent',
  components: {
    BForm,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BTableSimple,
    BThead,
  },
  props: {
    filterToggle: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: [Array, Object],
      default: () => ({}),
    },
    filterClass: {
      type: [Object, null],
      default: null,
    },
    selectedFilters: {
      type: [Array],
      default: () => ([]),
    },
  },
  computed: {
    ServiceOrder() {
      return ServiceOrder
    },
  },
  methods: {
    onFilter() {
      this.$emit('onFilter', this.filter)
    },
    removeSelectedFilter(path) {
      this.filterClass.removeSelectedFilter(this.filters, path)
      this.$emit('onRemoveSelectedFilter', path)
    },
    removeSearchFilter() {
      this.$emit('searchQueryClear')
    },
  },
  setup() {
    return {}
  },
}
</script>

<style scoped>

</style>
